$red-400: #ef5350;
$red-500: #f44336;
$blue-gray-50: #eceff1;
$blue-gray-100: #cfd8dc;
$blue-gray-900: #263238;

$white-color: #fff;
$black-color: #000;
$black-87-opacity: rgba($black-color, .87);
$black-54-opacity: rgba($black-color, .54);
$black-50-opacity: rgba($black-color, .5);
$black-38-opacity: rgba($black-color, .38);
$black-25-opacity: rgba($black-color, .25);
$transparent-color: transparent;

$primary-text-color: #212121;
$secondary-text-color: #757575;
$primary-color: #2677C3;
$dark-primary-color: #1976d2;
$secondary-color: #EEEFF3;
$accent-color: #ff4081;
$blue-gray-text-color: #545e6f;
$disabled-button-text-color: rgba($black-color, .26);

$default-page-margin: 12px;
$toolbar-z-index: 100;
$toolbar-shadow: 0 1px 4px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .12);
$toolbar-height: 64px;
$pre-html-background-color: #1f4662;

$post-item-info-label-color: #9e9e9e;
$very-light-input-underline: rgba($black-color, .1);

$new-posts-background-color: #f8f8f8;
$left-split-footer-background-color: #2677C3;
$left-split-footer-text-color: #E2EDF7;
$right-split-footer-background-color: #EEEFF3;
$right-split-footer-text-color: #1E1E1F;
$main-footer-background-color: #F8F8F8;
$main-footer-input-placeholder-color: #A5A5A5;
$login-page-background-color: #F8F8F8;
$posts-page-search-button-border-color: #B1B1B1;
$posts-page-search-button-text-color: #B3B3B3;
$quick-hover-access-home-button-background-color: #F8F8F8;

$input-label-color: #A5A5A5;

$roboto-font: Roboto, 'Helvetica Neue', sans-serif;
$roboto-mono-font: 'Roboto Mono', monospace;
