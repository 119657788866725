@import '../../styles/variables';

.quick-hover-access {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  position: fixed;
  right: 0;

  .hover-button {
    border-radius: 0 !important;
    height: 50px;
    position: absolute;
    right: 0;
    width: 30px;

    &:hover {
      box-shadow: $toolbar-shadow;
      border-radius: 3px;
      transition: all 0.5s ease 0s;
      width: 150px;

      .hover-button-label {
        display: inline-block;
      }
    }
  }

  .hover-button-label {
    font-family: $roboto-mono-font;
    font-weight: 400;
    display: none;
    margin-right: 20px;
  }

  .thoughts-button {
    background-color: $primary-color;
    color: $left-split-footer-text-color;

    &:hover {
      background-color: $primary-color;
      color: $left-split-footer-text-color;
    }
  }

  .tutorials-button {
    background-color: $secondary-color;
    color: $right-split-footer-text-color;
    top: 53px;
  }

  .home-button {
    background-color: $quick-hover-access-home-button-background-color;
    color: $right-split-footer-text-color;
    top: 106px;

    img {
      width: 30px;
    }
  }
}
