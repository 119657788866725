@import '../../styles/variables';

.main-footer {
  background-color: $main-footer-background-color;
  display: flex;
  height: 260px;
  position: relative;

  .left-side {
    align-items: center;
    display: flex;
    margin-left: 120px;
    width: 50%;

    .vertical-button-container {
      align-items: baseline;
      display: flex;
      flex-direction: column;

      .section-label {
        font-size: 14px;
        font-weight: 600;

        span {
          font-family: $roboto-mono-font;
          text-transform: capitalize;
        }
      }
    }
  }

  .right-side {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

    .updates-label {
      font-family: $roboto-mono-font;
      font-size: 12px;
      font-weight: 600;
      width: inherit;
    }

    .register-email-container {
      align-items: center;
      display: flex;
      width: inherit;

      .email-field {
        background-color: #D8D8D8;
        border-radius: 3px;
        bottom: 3px;
        font-size: 14px;
        position: relative;
        width: 300px;

        .MuiInputBase-input {
          font-family: $roboto-mono-font;
          font-size: 14px;
          padding: 6px 9px 7px;
        }

        .MuiInput-underline {
          &:before {
            border-bottom: 0;
          }

          &:after {
            border-color: $primary-color;
          }
        }
      }

      .submit-email-button {
        border-color: $right-split-footer-text-color;
        font-family: $roboto-mono-font;
        font-size: 12px;
        font-weight: 400;
        height: 28px;
        margin: 0 4px 0 10px;
        width: 100px;
      }
    }
  }

  .copyright-label {
    bottom: 18px;
    font-family: $roboto-mono-font;
    font-size: 12px;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}
