@import '../../styles/variables';

.header {
  background-color: $white-color;
  display: flex;
  height: 20vh;
  z-index: $toolbar-z-index;

  .left-side {
    align-self: center  ;
    display: flex;
    flex-direction: column;
    margin: 50px 100px;

    .toolbar-logo {
      cursor: pointer;
      max-width: 80px;
    }

    .social-media-handle {
      align-items: flex-end;
      font-family: $roboto-mono-font;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .right-side {
    align-self: flex-end;
    display: flex;
    margin: 50px 100px 38px;

    .toolbar-icon {
      cursor: pointer;
      margin: 0 10px;
      width: 18px;
    }
  }

  .inverted-text-color {
    color: $secondary-color;
  }
}
