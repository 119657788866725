@import './styles/variables';

* {
  font-family: $roboto-font;
}

body {
  margin: 0;
}

pre {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;

  .hljs {
    background-color: $secondary-color !important;
  }

  code {
    span {
      .hljs-name,
      .hljs-title {
        color: $primary-color !important;
      }
    }
  }
}

.spacer {
  flex: 1 1 auto;
}

// Override material
.MuiListItem-button {
  font-family: $roboto-mono-font !important;
}

.MuiInputLabel-shrink {
  font-family: $roboto-mono-font !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary-color !important;
}

.MuiInputBase-input {
  font-family: $roboto-mono-font !important;

  &::placeholder {
    color: $main-footer-input-placeholder-color !important;
  }
}

.MuiInput-underline {
  &:after {
    border-color: $primary-color !important;
  }
}
