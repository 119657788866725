@import '../../../styles/variables';

.post-page {
  background-color: $white-color;
  display: flex;
  justify-content: center;

  .loading-spinner-container {
    display: flex;
    height: 0;

    .MuiCircularProgress-colorPrimary {
      color: $primary-color
    }

    .loading-spinner {
      margin: auto;
      position: relative;
      top: 25px;
    }
  }

  .form-label {
    color: $black-54-opacity;
    font-family: $roboto-mono-font;
    font-size: 12px;
  }

  .form-label-error {
    color: $red-500;
  }

  .post-content {
    margin-bottom: 5px;
    width: 53%;

    .post-header-container {
      margin-top: 80px;

      .post-title {
        color: #212121;
        display: flex;
        font-family: $roboto-mono-font;
        font-size: 26px;
        font-weight: 600;
        justify-content: space-between;

        .edit-button {
          color: $primary-color;
        }
      }

      .post-subtitle {
        color: #7F7F7F;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        margin: 30px 0 100px 0;

        .post-timestamps-container {
          display: flex;
          flex-direction: column;
        }

        .post-author,
        .post-timestamp {
          font-family: $roboto-mono-font;
        }
      }
    }

    .post-body {
      font-family: $roboto-font;
      font-size: 16px;
      line-height: 1.6;

      .show-comments-button-container {
        margin: 20px 0;
        text-align: center;
        width: 100%;
      }

      .extra-margin-with-disqus-open {
        margin-bottom: 15px;
      }

      .tiny-mce-editor {
        margin-bottom: 15px;
      }

      .tags-footer-container {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 5px;

        .tags-container {
          align-items: center;
          display: flex;
        }

        .links-container {
          width: 20%;

          img {
            cursor: pointer;
            margin: 5px 0 0 20px;
          }
        }

        .divider-line {
          background-color: $black-color;
          bottom: -10px;
          height: 3px;
          position: absolute;
          width: 100%;
        }
      }
    }

    .error-input-hint {
      color: $red-500;
      font-family: $roboto-mono-font;
      font-size: 12px;
      font-weight: 400;
    }

    .editor-hint-container {
      margin: 20px 0;
    }

    .action-buttons-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .action-button {
        color: $primary-color;

        span {
          font-family: $roboto-mono-font;
        }
      }
    }
  }

  .create-post-header-container {
    .form-field {
      font-size: 16px;
      margin-bottom: 10px;
      width: 100%;

      label {
        color: $input-label-color;
        font-family: $roboto-mono-font;
      }

      .input-helper-text {
        display: block;
        text-align: right;
      }
    }

    .description-form-field {
      margin-bottom: 30px;
      margin-top: -10px;
    }

    .create-tags-container {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 38px;

      .tags-text-field {
        flex: auto;
      }

      input {
        font-family: $roboto-mono-font;
      }
    }

    .type-of-post-container {
      margin-bottom: 40px;

      .MuiSelect-selectMenu {
        font-family: $roboto-mono-font;
      }

      label {
        color: $input-label-color;
        font-family: $roboto-mono-font;
        font-size: 16px;
      }

      input {
        font-family: $roboto-mono-font;
      }

      .post-type-dropdown {
        width: 100%;

        .post-type-menu-item {
          color: blue;
        }
      }
    }

    .upload-post-image-container {
      align-items: center;
      display: flex;
      flex-direction: column;

      .default-image {
        max-height: 450px;
        max-width: 450px;

        img {
          max-height: 450px;
          max-width: 450px;
        }
      }

      .no-image-selected {
        background-color: $blue-gray-50;
        min-height: 450px;
        min-width: 450px;
      }

      .upload-button {
        color: $primary-color;
        margin: 15px 0 5px 0;
        width: 250px;

        span {
          font-family: $roboto-mono-font;
        }
      }

      .hidden-input {
        display: none;
      }

      .image-size-tip {
        color: $secondary-text-color;
        font-family: $roboto-mono-font;
        font-size: 12px;
        margin: 25px auto;
      }
    }

    .upload-image-bar {
      margin-bottom: 10px;
    }
  }
}
