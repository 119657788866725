@import '../../../styles/variables';

.auth-page {
  background-color: $login-page-background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $toolbar-height 0;

  .card-form {
    align-self: center;
    min-width: 662px;

    .card-header {
      margin-top: 36px;

      .title {
        color: $black-54-opacity;
        font-family: $roboto-mono-font;
        font-size: 18px;
        text-align: center;
      }

      .subtitle {
        color: $black-54-opacity;
        font-family: $roboto-mono-font;
        font-size: 24px;
        margin-top: 10px;
        text-align: center;
      }
    }

    .form {
      margin: 30px auto 0;

      .form-group {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .form-field {
          font-size: 16px;
          width: 40%;

          .form-label {
            color: $black-54-opacity;
            font-family: $roboto-mono-font;
            font-size: 12px;
          }

          .form-label-error {
            color: $red-500;
          }
        }
      }

      .login-button-container {
        margin: 0 auto;
        width: 40%;

        .login-button {
          background-color: $primary-color;
          box-shadow: $toolbar-shadow;
          color: $white-color;
          font-weight: 400;
          height: 37.8px;
          letter-spacing: .5px;
          line-height: 16pt;
          margin: 15px 0;
          text-transform: uppercase;
          width: 100%;

          &:disabled {
            color: $left-split-footer-text-color;
            opacity: 0.8;
          }
        }

        .extra-buttons-container {
          display: flex;
          justify-content: space-between;
        }
      }

      .warning-message-container {
        align-items: center;
        text-align: justify;
        margin: 30px auto 24px;
        width: 372px;

        .label {
          color: $black-54-opacity;
          font-family: $roboto-mono-font;
          font-size: 12px;
        }
      }
    }
  }
}
