@import '../../styles/variables';

.split-footer {
  display: flex;

  .left-side {
    display: flex;
    background-color: $left-split-footer-background-color;
    color: $left-split-footer-text-color;
    justify-content: center;
    height: 400px;
    width: 50%;
  }

  .right-side {
    display: flex;
    background-color: $right-split-footer-background-color;
    color: $right-split-footer-text-color;
    justify-content: center;
    height: 400px;
    width: 50%;
  }

  .info-layout {
    display: flex;
    flex-direction: column;
    margin-top: 75px;

    .type-header {
      font-family: $roboto-mono-font;
      font-size: 40px;
      font-weight: 600;
    }

    .type-icon {
      margin-left: 20px;
      position: relative;
      top: 2px;
      width: 30px;
    }

    .posts-label {
      font-family: $roboto-mono-font;
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
    }

    .posts-count {
      font-family: $roboto-mono-font;
      font-size: 56px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }

  .horizontal-layout {
    display: flex;
  }

  .last-update-container {
    display: flex;
    flex-direction: column;

    .last-update-label {
      font-family: $roboto-mono-font;
      font-size: 18px;
      font-weight: 600;
    }

    .last-update-date-label {
      font-family: $roboto-mono-font;
      font-size: 13px;
      margin-top: 5px;
    }
  }

  .explore-button {
    border-color: $left-split-footer-text-color;
    color: $left-split-footer-text-color;
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    margin-left: 75px;
    width: 140px;

    span {
      font-family: $roboto-mono-font;
    }
  }

  .dark-border-color {
    border-color: $right-split-footer-text-color;
    color: $right-split-footer-text-color;
  }
}
