@import '../../styles/variables';

.new-posts {
  background-color: $new-posts-background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0 90px 0;

  .cards-container {
    display: flex;
    justify-content: center;
  }

  .new-posts-label {
    font-family: $roboto-mono-font;
    font-weight: 600;
    font-size: 28px;
    position: relative;
    text-align: center;
    top: -30px;
  }
}
